body {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  background-color: #000;
} // end of bodys

a {
  color: #fff;
}

.section {
  min-height: 100vh;
  padding: 20px;
} // end of .section

#section-1 {
  @extend .flex-column;
  justify-content: center;
  align-items: center;

  #logo {
    width: 480px;
    height: 360px;

    @media (max-width: 767px) {
      width: 290px;
      height: auto;
    } // end of @media (max-width: 767px)

  } // end of #logo

  h1 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 48px;
    margin-top: 50px;
    text-align: center;
  } // end of h1

  .contact-email {
    display: block;
    margin-top: 50px;
  }

} // end of #section-1

#gmap {
  display: table;
  width: 640px;
  height: 360px;
  margin: 0 auto 50px;
  border-radius: 8px;
  overflow: hidden;

  iframe {
    //height: 100%;
    height: 360px;
  } // end of iframe

  @media (max-width: 767px) {
    width: 90%;
    iframe {
      width: 100%;
    } // end of iframe
  } // end of @media (max-width: 767px)

} // end of #gmap